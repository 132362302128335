<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('generalLedgerCode.tablePageTitle') | title" />

    <huboo-table
      id="generalLedgerCodeTable"
      @filter="handleFilter"
      @row-clicked="onSelected"
      @search="handleSearch"
      @update:options="handleUpdateOptions"
      @remove="openConfirmationModal"
      @confirm-delete="onRemove"
      :filters="filters"
      :headers="headers"
      :items="items"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      clickable
      hide-search
      localPagination
      :remove="isAdmin"
      v-bind="options"
    >
      <template #actions>
        <v-btn
          id="create-general-ledger-code-button"
          :loading="createModalLoading"
          :disabled="loading || !isAdmin"
          color="primary"
          @click="openCreateModal"
        >
          {{ $t('generalLedgerCode.createModal.createButton') }}
        </v-btn>
      </template>
    </huboo-table>
    <edit-modal
      v-model="editModal"
      :selected="selected"
      @close="onModalClose"
      @updated-item="onUpdatedItem"
    />
    <create-modal v-model="createModal" @close="closeCreateModal" @updated-item="onUpdatedItem" />
  </huboo-page>
</template>

<script>
import { mapGetters } from 'vuex'
import { handleSearch } from '@/views/pages/billing/billingViewHelpers.js'
import { title } from '@/utilities/filters'
import { isLoading } from '@/utilities/helpers/views'
import ClientMixin from '@/mixins/client'
import ModalActionsMixin from '@/mixins/modalActions'
import EditModal from './GeneralLedgerCodeEditModal.vue'
import CreateModal from './GeneralLedgerCodeCreateModal.vue'

export default {
  name: 'GeneralLedgerCode',
  mixins: [ClientMixin, ModalActionsMixin],
  components: {
    'edit-modal': EditModal,
    'create-modal': CreateModal,
  },
  data: () => ({
    createModal: false,
    editModal: false,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    search: '',
    selected: null,
  }),
  computed: {
    ...mapGetters('core', ['getIsAdmin']),
    isAdmin() {
      return this.getIsAdmin
    },
    createModalLoading() {
      return isLoading(this.createUrl, 'POST')
    },
    createUrl() {
      return 'general-ledger-code/'
    },
    generalLedgerCodeHeaders() {
      return [
        {
          text: title(this.$t('generalLedgerCode.fields.code')),
          sortable: false,
          value: 'code',
        },
        {
          text: title(this.$t('generalLedgerCode.fields.name')),
          sortable: false,
          value: 'name',
        },
        {
          text: title(this.$t('generalLedgerCode.fields.updatedAt')),
          sortable: false,
          value: 'updatedAt',
        },
      ]
    },
    getGeneralLedgerCodes() {
      return this.$store.getters['generalLedgerCode/getAll']
    },
    headers() {
      return this.generalLedgerCodeHeaders
    },
    items() {
      return this.generalLedgerCodes.map(bh => {
        const v = { ...bh }
        v.updatedAt = this.formatDate(v.updatedAt)
        return v
      })
    },
    loading() {
      return isLoading(this.url)
    },
    meta() {
      return this.getGeneralLedgerCodes?.meta || { total: this.items.length }
    },
    generalLedgerCodes() {
      const lists = this.getGeneralLedgerCodes
      if (Array.isArray(lists))
        return lists.map(l => ({
          ...l,
          loading: this.removeLoading(l.id),
        }))
      else return []
    },
    url() {
      return this.urlEntity
    },
    urlEntity() {
      return '/general-ledger-code/'
    },
  },
  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  methods: {
    fetch() {
      const { options, search } = this
      const { page } = options
      const params = {}
      if (page) params.page = page
      if (search) params.query = search
      return this.$store.dispatch('generalLedgerCode/fetchCollection', { params })
    },
    handleSearch(e) {
      return handleSearch(this, e)
    },
    async onRemove() {
      await this.$store.dispatch('generalLedgerCode/delete', this.selected.id)
    },
    openConfirmationModal(selectedRow) {
      this.selected = selectedRow
    },
    onSelected(e) {
      // disable select if not admin
      if (!this.isAdmin) return
      const selectedArray = Object.entries(e)
      const stringToBool = selectedArray.map(item => {
        if (item[1] === 'Yes') {
          return [item[0], true]
        }
        if (item[1] === 'No') {
          return [item[0], false]
        } else {
          return item
        }
      })

      this.selected = stringToBool.reduce((acc, item) => {
        acc[item[0]] = item[1]
        return acc
      }, {})
      this.editModal = true
    },

    onUpdatedItem() {
      this.fetch()
    },

    removeLoading(id) {
      return this.$store.getters['core/apiEndpointIsLoading']({
        url: this.urlEntity + id,
        method: 'DELETE',
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
