<template>
  <div class="fields-container">
    <hub-text-field
      @keyup="onInputFocus('code')"
      :error-messages="(errorMessages && errorMessages.code) || []"
      class="modal-form__field"
      name="code"
      v-model="formData.code"
      :label="$t('generalLedgerCode.fields.code')"
    />
    <div class="break" />
    <hub-text-field
      @keyup="onInputFocus('name')"
      :error-messages="(errorMessages && errorMessages.name) || []"
      class="modal-form__field"
      name="name"
      v-model="formData.name"
      :label="$t('generalLedgerCode.fields.name')"
    />
  </div>
</template>

<script>
export default {
  name: 'InputFields',
  props: {
    formData: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  computed: {
    errorMessages() {
      return this.$store.getters['core/validationErrors']
    },
    isAdmin() {
      return this.$store.getters['core/getIsAdmin']
    },
  },
  methods: {
    onInputFocus(inputName) {
      this.removeError(inputName)
    },
    removeError(field) {
      this.$store.commit('core/removeError', field)
    },
  },
  watch: {},
}
</script>

<style>
.fields-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.fields-container > * {
  flex-grow: 2;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.hub-switch {
  margin: 0;
  height: 1.25rem;
}
</style>
