<template>
  <modal-form
    :value="value"
    id="general-ledger-code-form"
    lazy-validation
    :loading="loading"
    :fields="formData"
    :active-field="activeField"
  >
    <template #header>
      {{ $t('generalLedgerCode.createModal.title') }}
    </template>

    <template #fields>
      <InputFields :form-data="formData" />
    </template>

    <template #form-actions>
      <hub-button name="cancel" variant="secondary" @click="closeAndClear" id="cancelButton">
        {{ $t('common.cancel') }}
      </hub-button>
      <hub-button name="save" @click="onSubmit" id="save-button" :loading="loading">
        {{ $t('common.save') }}
      </hub-button>
    </template>
  </modal-form>
</template>

<script>
import InputFields from './InputFields.vue'

export default {
  name: 'GeneralLedgerCodeCreateModal',
  components: {
    InputFields,
  },
  props: {
    value: Boolean,
  },

  data() {
    return {
      formData: {
        name: '',
        code: null,
      },
      activeField: '',
    }
  },

  computed: {
    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: '/general-ledger-code/',
      })
    },
  },

  methods: {
    async onSubmit() {
      const res = await this.$store.dispatch('generalLedgerCode/create', {
        data: {
          ...this.formData,
          code: this.formData.code,
        },
      })

      if (res?.status !== 201) return

      this.$emit('close')
      this.$emit('updated-item')
      this.formData = {
        name: '',
        code: null,
      }
    },

    closeAndClear() {
      this.$emit('close')
      this.formData = {
        name: '',
        code: null,
      }
    },
  },
}
</script>

<style>
.fields-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.fields-container > * {
  flex-grow: 2;
}
.break {
  flex-basis: 100%;
  height: 0;
}
</style>
